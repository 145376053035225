export const shebaBannerGif = [
  {
    image:
      "https://res.cloudinary.com/dxodgllun/image/upload/v1706765905/ayykori_management/default/lcowanc4myt29amqx3wh.gif",
    id: "1",
  },
];
export const campaignBanner = [
  {
    image:
      "https://images.ayykori.net/api/v1/images/get/gif/campaign-landing-gif.gif",
    id: "1",
  },
  {
    image: "https://images.ayykori.net/api/v1/images/get/gif/gift-offer.gif",
    id: "2",
  },
  {
    image:
      "https://res.cloudinary.com/drh5f7rb3/image/upload/v1710652657/ayykori_management/default/ddhzdkh5vwdnucwtpif6.gif",
    id: "3",
  },
];
