import Cookies from "js-cookie";
import React from "react";
import { useLocation } from "react-router-dom";
import { useGetBrandsByKeyValueQuery } from "../../features/brand/brandApiSlice";
import BrandCard from "../Card/BrandCard";
import CommonComponent from "../CommonComponent/CommonComponent";
import Error from "../Error/Error";
import NoDataFound from "../NodataFound/NoDataFound";
import BrandCardSkeleton from "../Skeleton/BrandCardSkeleton";
const TopnotchCashBack = () => {
    const { pathname } = useLocation();
    const access_token = Cookies.get("ayykori");
    const { data, isLoading, isSuccess, isError } = useGetBrandsByKeyValueQuery(
        {
            access_token,
            limit: 200,
            key: "topnotch_cashbacks",
        },
        { refetchOnReconnect: true }
    );

    let content = null;
    // handle loading case
    if (isLoading && !isSuccess) {
        content = (
            <>
                <div className="w-full px-3 md:px-[55px] justify-center grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-3 md:gap-y-6 gap-x-3 md:gap-x-8">
                    {Array(8)
                        .fill()
                        ?.map((item, i) => (
                            <BrandCardSkeleton key={i} />
                        ))}
                </div>
            </>
        );
    }

    // handle success case
    if (!isLoading && isSuccess && !isError && data) {
        const sortOrder = [
            "daraz.com.bd",
            "medeasy.health",
            "shohoz.com/bus-tickets",
            "chaldal.com",
            "pickaboo.com",
            "bazar365.com",
            "keeron.com",
            "khaasfood.com",
            "banglanatural.com",
            "greengrocery.com.bd",
            "ribana.com.bd",
            "romoni.com.bd",
            "oneummahbd.shop",
            "ostad.app",
            "bestelectronics.com.bd",
            "othoba.com",
            "bishworang.com.bd",
            "sadakalo.net",
            "priyojon.care",
            "furnicombd.com",
            "hoco.com.bd",
            "proteinmarket.com.bd",
            "bimafy.com",
            "khelbei.com",
            "goofiworld.com",
            "bishworang.com.bd",
            "vistabangladesh.com",
            "1000fix.com",
            "brihaat.com",
            "shohure.com.bd",
            "stygen.gift",
            "learning.uvtrbd.com",
            "aalaadin.com",
            "shikkha.xyz",
            "amaderacademy.com",
            "bacchakaccha.com",
            "beauti4me.com",
            "asklegalx.com",
            "brandboxbd.com",
            "boichitro.shop",
            "gearexact.com",
            "focallurebangladesh.com",
            "manfarebd.com",
            "olabbd.com",
            "dazzyonline.com",
            "esquireelectronicsltd.com",
            "deencommerce.com",
            "pinkflashbd.com",
            "easydhakabd.com",
            "cosmeticsign.com",
            "glamtouch.com.bd",
            "ensign.com.bd",
            "oneummahbd.com",
            "organiconline.com.bd",
            "citizensports.com.bd",
            "in2travels.com",
            "paperboat.shop",
            "marssfootwear.com",
            "ihwbd.com",
            // Add other domain values in the desired order
        ].map((val) => val.toLowerCase());

        const sortedResData = [...data.resData].sort(
            (a, b) =>
                sortOrder.indexOf(a.brandDomain.toLowerCase()) -
                sortOrder.indexOf(b.brandDomain.toLowerCase())
        );
        const first8Items = sortedResData.slice(0, 28);

        content = (
            <>
                <div className="w-full px-3 md:px-[55px] justify-center grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-3 md:gap-y-6 gap-x-3 md:gap-x-8">
                    {first8Items &&
                        first8Items?.map((item, i) => (
                            <BrandCard
                                key={i}
                                data={{
                                    id: item?.brandId,
                                    brandUnid: item?.brandUnid,
                                    name: item?.brandName,
                                    brandCardimg: item?.brandimg,
                                    brandHomepageLink: item?.brandHomepageLink,
                                    brandType: item?.brandType,
                                    userCommission:
                                        pathname === "/affiliate"
                                            ? item?.earningUserCommission
                                            : item?.cashbackUserCommission,
                                    borderShow: true,
                                    subBrand: "No",
                                    type:
                                        pathname === "/affiliate"
                                            ? "earning"
                                            : "cashback",
                                }}
                            />
                        ))}
                </div>
            </>
        );
    }

    // handle error case
    if (!isLoading && isError) {
        content = (
            <>
                <Error />
            </>
        );
    }
    // handle empty case
    if (!isLoading && isSuccess && data?.resData?.length === 0) {
        content = (
            <>
                <NoDataFound />
            </>
        );
    }
    return (
        <div className=" my-10 md:my-16">
            <CommonComponent
                data={{
                    title: "Exclusive Brands",
                    end: "Ends in:  00:00:00",
                    link: "/brands/topnotch_cashbacks",
                    linktitle: "See All",
                    show: true,
                }}
            />
            {content}
        </div>
    );
};

export default TopnotchCashBack;
